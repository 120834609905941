import React from "react";
import {
  Center,
  Text,
  Heading,
  Image,
  HStack,
  Stack,
  FormControl,
  Input,
  Button,
  useToast,
} from "native-base";
import { useParams, useNavigate } from "react-router-dom";
import background from "../assets/background.png";
import logo from "../assets/logo.svg";
import autoDebit from "../assets/autodebit.png";

export default function Success() {
  const navigate = useNavigate();

  React.useState(() => {
    const timer = setTimeout(() => {
      window.open("https://donewelllife.com.gh/signup/", "_self");
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Center h="100vh" bg="amber.500">
      <img
        src={background}
        alt="background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -100,
        }}
      />

      <Center w={["95%", "60%", "50%"]} bg="white" p={5} rounded="md">
        <HStack justifyContent="space-between" alignItems="center" w="90%">
          <Image
            source={logo}
            alt="logo"
            accessibilityLabel="logo"
            w={["10vh", "14vh"]}
            h={["3vh", "5vh"]}
            mt={5}
            resizeMode="contain"
          />
          <Image
            source={autoDebit}
            alt="auto-debit"
            accessibilityLabel="logo"
            w={["10vh", "14vh"]}
            h={["3vh", "5vh"]}
            mt={5}
            resizeMode="contain"
          />
        </HStack>

        <Center mt={10} w="80%" space={5}>
          <Heading mb={2} textAlign="center">
            Payment Process Completed.
          </Heading>
          <Text fontSize="xl" textAlign="center">
            Kindly check your phone for a confirmation to complete your
            transaction.
          </Text>
          <Text fontSize="xl" textAlign="center" mb={5}>
            Thank You!
          </Text>
        </Center>
      </Center>
    </Center>
  );
}
