import React from "react";
import { Button, Center, Text, Image } from "native-base";
import error from "../assets/error.svg";

export default function Error() {
  return (
    <Center h="100vh" bg="gray.100">
      <Image source={error} size="2xl" />

      <Text mt={10} mb={4}>Page Not Found</Text>

      <Button
        size='sm'
        colorScheme="red"
        onPress={() =>
          window.open("https://donewelllife.com.gh/signup/", "_self")
        }
      >
        Go Home
      </Button>
    </Center>
  );
}
