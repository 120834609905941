import React, { useState } from "react";
import {
  Center,
  Text,
  Heading,
  HStack,
  Image,
  Stack,
  FormControl,
  Input,
  Button,
  useToast,
} from "native-base";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import background from "../assets/background.png";
import logo from "../assets/logo.svg";
import autoDebit from "../assets/autodebit.png";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Error from "./Error";
import axios from "axios";

export default function ValidateCode() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [code, setCode] = useState("");
  const [validateLoading, setValidateLoading] = useState(false);
  const [user, setUser] = useState({});

  // get user data
  const getUserDate = () => {
    axios(`${process.env.REACT_APP_GET_USER_DETAILS}${id}`)
      .then((res) => {
        setUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   console.log(user);

  //get data at useEffect
  React.useEffect(() => {
    if (id === ":id") {
      window.open("https://donewelllife.com.gh/signup/", "_self");
      return;
    }
    getUserDate();
  }, []);

  //validate code
  const validateCode = async () => {
    setValidateLoading(true);

    // https://app.donewelllife.com.gh/DonewellLife/ValidateMomonumber?code=123&momoNumber=233244053535

    return axios
      .post(
        `${process.env.REACT_APP_VALIDATE_CODE}code=${code}&momoNumber=${user.momoNumber}`
      )
      .then((res) => {
        console.log(res.data.success);
        setValidateLoading(false);
        if (res.data.success === true) {
          Swal.fire({
            title: "OTP validated Successfully",
            text: "Click on the PAY NOW button below to make payment",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Pay Now",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const pathParams = new URLSearchParams({
                clientPhone: user.momoNumber,
                amount: user.premium,
                frequency: 1,
                frequencyType: user.frequencyType,
                startDate: user.startDate,
                endDate: user.endDate,
                debitDay: user.debitDay,
                policyNumber: user.policyNumber,
                source: "react",
                thirdPartyReferenceNo: id,
              });

              return fetch(
                `${process.env.REACT_APP_CREATE_MANDATE}${pathParams}`
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(response.statusText);
                  }
                  return response.json();
                })
                .catch((error) => {
                  Swal.showValidationMessage(`Request failed: ${error}`);
                  console.log(error);
                });
            },
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(result);
              if (result.value.data.responseCode === "03") {
                Swal.fire({
                  title: `SUCCESS`,
                  icon: "success",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/success");
                  }
                });
                return;
              }

              Swal.fire({
                title: `${result.value.data.responseMessage}`,
                icon: "error",
              });
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Your OTP is incorrect, Please try again",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: err.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          }
        });
        setValidateLoading(false);
      });
  };

  //   validate code event handler
  const onValidate = () => {
    if (!code) {
      toast.show({
        title: "Fields cannot be empty!",
        status: "warning",
        placement: "top",
        duration: 3000,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to validate your OTP!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, validate!",
    }).then((result) => {
      if (result.isConfirmed) {
        validateCode();
      }
    });
  };

  if (id === ":id") {
    return <Error />;
  }

  return (
    <Center h="100vh">
      <img
        src={background}
        alt="background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -100,
        }}
      />

      <Center w={["95%", "60%", "50%"]} bg="white" p={5} rounded="md">
        <HStack
          justifyContent="space-between"
          alignItems="center"
          w="90%"
        >
          <Image
            source={logo}
            alt="logo"
            accessibilityLabel="logo"
            w={["10vh", "14vh"]}
            h={["3vh", "5vh"]}
            mt={5}
            resizeMode="contain"
          />
          <Image
            source={autoDebit}
            alt="auto-debit"
            accessibilityLabel="logo"
            w={["10vh", "14vh"]}
            h={["3vh", "5vh"]}
            mt={5}
            resizeMode="contain"
          />
        </HStack>

        <Stack mt={10} w="90%" space={5}>
          <FormControl>
            <FormControl.Label>
              Enter the OTP code that was sent to your phone
            </FormControl.Label>
            <Input
              _focus={{ borderColor: "gray.300" }}
              value={code}
              onChangeText={setCode}
            />
          </FormControl>

          <Button
            my={4}
            colorScheme="red"
            onPress={onValidate}
            isLoading={validateLoading}
            _loading={{ bg: "red.500" }}
          >
            VALIDATE OTP
          </Button>
        </Stack>
      </Center>
    </Center>
  );
}
