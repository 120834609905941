import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import ValidateCode from "../pages/ValidateCode";
import Success from "../pages/Success";
import Error from "../pages/Error";

export default function RootLayout() {

  const location = useLocation();


  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/validate/:id" />} />
      <Route path="/validate/:id" element={<ValidateCode />} />
      <Route path="/success" element={<Success />} />
      <Route path="*" element={<Error/>} />
    </Routes>
  );
}
